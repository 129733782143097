import { useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { listMediaSeries } from '../apis';
import StickerLine from '../assets/resource_line.svg';
import MobileStickerLine from '../assets/resource_lineMobile.svg';
import StickerIcon from '../assets/resource_sticker.svg';
import MobileStickerIcon from '../assets/resource_stickerMobile.svg';
import Loading from '../components/common/loading';
import CommonBottomBar from '../components/common/navbar/CommonBottomBar';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import TitleGroup from '../components/common/typographies/TitleGroup';
import AgreementDialog from '../pageComponents/mediaSeries/AgreementDialog';
import MediaSeriesList from '../pageComponents/mediaSeries/MediaSeriesList';
import colors from '../utils/colors';
import { breakpoint, ResponsiveUI } from '../utils/responsive';

const Assets = () => {
  const isMobile = useMediaQuery(breakpoint.mobile);
  return (
    <div style={{ position: 'relative', top: 0, left: '100%' }}>
      <div
        style={{
          position: 'absolute',
          top: isMobile ? -65 : -70, // compensate the height of the navbar
          transform: 'translateX(-100%)',
        }}
      >
        <ResponsiveUI
          desktop={<Sticker src={StickerIcon} />}
          mobile={<div></div>}
        />
        <ResponsiveUI
          desktop={<Line src={StickerLine} style={{ width: '350px' }} />}
          mobile={<Line src={MobileStickerLine} />}
        />
        <ResponsiveUI
          desktop={<div></div>}
          mobile={
            <MobileSticker>
              <img src={MobileStickerIcon} alt="like for you" />
            </MobileSticker>
          }
        />
      </div>
    </div>
  );
};

const MediaSeries = () => {
  const { isLoading, isError, data, error, refetch } = useQuery(
    ['mediaSeries'],
    () => listMediaSeries()
  );

  const disableRightClick = useCallback((event) => {
    event.preventDefault();
  }, []);
  useEffect(() => {
    window.addEventListener('contextmenu', disableRightClick);
    return () => window.removeEventListener('contextmenu', disableRightClick);
  }, []);

  if (isLoading || !data) {
    return <Loading />;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Container>
        <CommonNavBar theme="black" />
        <Assets />
        <TitleGroupContainer>
          <TitleGroup zhTitle="多媒體區" enTitle="Media" color={colors.black} />
        </TitleGroupContainer>
        <MediaSeriesList data={data.data} />
        <CommonBottomBar theme="black" bgColor="greyBlue" />
        <AgreementDialog />
      </Container>
    </>
  );
};

const TitleGroupContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 137px;

  @media ${breakpoint.desktop} {
    margin-top: 47px;
    margin-bottom: 76px;
  }
`;

const Container = styled.div`
  background-color: #b7c2cd;
  position: relative;
`;
const Sticker = styled.img`
  user-drag: none;
  position: absolute;
  right: 0px;
  top: 60px;
  z-index: 10;
`;

const MobileSticker = styled.div`
  user-drag: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -70px;
`;
const Line = styled.img`
  user-drag: none;
  right: 0px;
  top: 0px;
  z-index: 1;

  @media ${breakpoint.desktop} {
    position: absolute;
  }
`;
export default MediaSeries;
