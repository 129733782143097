import styled from "styled-components";
import Grid from "@mui/material/Grid";
import CommonText from "../../components/common/typographies/CommonText";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../../assets/download.svg";
import PlayIcon from "../../assets/resource_play.svg";
import RadioImage from "../../assets/resource_radioImage.png";
import DownloadDialog from "./DownloadDialog";
import { useState, createRef, useRef } from "react";
import { breakpoint, WindowSize } from "../../utils/responsive";
import Masonry from "@mui/lab/Masonry";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import MediaLightbox from "./MediaLightbox";
import { useMemo } from "react";
import { useCallback } from "react";
import { downloadMediaImage } from "../../utils/download";
import AudioDisplay from "./MediaItem/Audio";
import ReactGA from "react-ga4";

const MediaGallery = ({ data, isOpenLightbox, setIsOpenLightbox }) => {
  const { t, i18n } = useTranslation("mediaSeriesDetail");
  const [isOpenDownloadDialog, setIsOpenDownloadDialog] = useState(false);
  //const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const AudioRef = useRef({});

  const _renderButton = (type, idx) => {
    switch (type) {
      case "VIDEO":
      case "STREAM":
        return (
          <PlayerButton>
            <CommonImage src={PlayIcon} />
          </PlayerButton>
        );
      case "VISUAL":
        return (
          <DownloadButton
            onClick={(e) => {
              e.stopPropagation();
              handleImageDownload(idx);
            }}
          >
            <CommonImage src={DownloadIcon} />
            <DownloadText>
              <CommonText
                style={{ zIndex: 50 }}
                fontSize="16px"
                fontWeight="600"
                lineHeight="200%"
                color="#fff"
              >
                {t("download")}
              </CommonText>
            </DownloadText>
          </DownloadButton>
        );
      default:
        return null;
    }
  };

  const handleImageDownload = useCallback((idx) => {
    if (data[idx].images.length > 1) {
      setIsOpenDownloadDialog(idx);
    } else {
      ReactGA.event("photo_download", {
        photo_name: data[idx].filename,
      });
      ReactGA.event("download_media", {
        photo_name: data[idx].filename,
      });
      downloadMediaImage(data[idx].images[0].media, data[idx].filename);
      setIsOpenDownloadDialog(false);
    }
  }, []);

  const handleOnPlayAudio = (idx, item, isFirstPlay) => {
    if (isFirstPlay) {
      ReactGA.event("audio_start", {
        audio_title: item.filename,
      });
    }
    if (AudioRef) {
      for (const key of Object.keys(AudioRef.current)) {
        if (key != idx) {
          AudioRef.current[key].audio.current.pause();
        }
      }
    }
  };

  const handleOpenLightbox = (idx, type) => {
    ReactGA.event("photo_view", {
      photo_name: data[idx].filename,
    });
    if (type !== "AUDIO") {
      // pause all audio
      if (AudioRef) {
        for (const key of Object.keys(AudioRef.current)) {
          AudioRef.current[key].audio.current.pause();
        }
      }
      setIsOpenLightbox(idx);
    }
  };

  const renderMasonryItems = (data) => {
    return data.map((media, idx) => (
      <MediaContainer
        onClick={() => {
          handleOpenLightbox(idx, media?.type);
        }}
      >
        {media?.type === "AUDIO" ? (
          <AudioDisplay
            value={media}
            idx={idx}
            audioRef={AudioRef}
            handleOnPlay={(idx, isFirstPlay) =>
              handleOnPlayAudio(idx, media, isFirstPlay)
            }
          />
        ) : (
          <ImageContainer>
            <MediaImage src={media?.thumbnail + "-thumbnail"} />
            <ImageInfoBg>
              <ImageInfo>
                <Description>
                  {i18n.language === "zh" ? media.captionZh : media.captionEn}
                </Description>
                {_renderButton(media?.type, idx)}
              </ImageInfo>
            </ImageInfoBg>
          </ImageContainer>
        )}
      </MediaContainer>
    ));
  };

  const matchesSM = useMediaQuery("(min-width: 600px)");

  return (
    <>
      <MasonryContainer>
        {data.length >= 3 ? (
          <StyledMasonry
            isDesktop={matchesSM}
            columns={{ xs: 1, sm: 2, md: 2, lg: 3 }}
            spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
          >
            {renderMasonryItems(data)}
          </StyledMasonry>
        ) : (
          <StyledMasonry
            isDesktop={matchesSM}
            columns={{ xs: 1, sm: 2, md: 2 }}
            spacing={{ xs: 2, sm: 2, md: 3 }}
            style={{ alignContent: "center" }}
          >
            {renderMasonryItems(data)}
          </StyledMasonry>
        )}
      </MasonryContainer>

      <MediaLightbox
        initialIndex={isOpenLightbox}
        data={data}
        lightboxOpen={isOpenLightbox}
        setLightboxOpen={setIsOpenLightbox}
        handleImageDownload={handleImageDownload}
      />

      <DownloadDialog
        data={data[isOpenDownloadDialog]}
        isOpenDialog={isOpenDownloadDialog}
        setIsOpenDialog={setIsOpenDownloadDialog}
      />
    </>
  );
};

const MasonryContainer = styled(Box)`
  margin: auto;
  max-width: 1820px;
  position: relative;
  z-index: 1;
`;

const StyledMasonry = styled(Masonry)`
  margin: 0 !important;
  // use padding inside the masonry to control the margin between the window and this container

  //padding: ${(props) => (props.isDesktop ? "0px 50px" : "0px 20px")}
`;

const CommonImage = styled.img`
  user-drag: none;
`;

const DownloadButton = styled.div`
  width: auto;
  height: 40px;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 6px;
  padding: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;

const PlayerButton = styled.div`
  border-radius: 99px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(249, 249, 249, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  flex-shrink: 0;

  @media (min-width: 1201px) {
    height: 64px;
    width: 64px;
    margin-bottom: 5px;
  }
  @media (max-width: 1200px) {
    height: 56px;
    width: 56px;
    margin-bottom: 0px;
  }
`;

const ImageInfoBg = styled.div`
  z-index: 50;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  position: absolute;
  bottom: 2px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
`;

const ImageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1201px) {
    padding: 40px 16px 16px 16px;
  }
  @media (max-width: 1200px) {
    padding: 40px 10px 10px 10px;
  }
`;

const Description = styled.div`
  border-radius: 6px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  z-index: 50;
  color: #fff;
  font-weight: 600;
  line-height: 140%;

  @media (min-width: 1201px) {
    font-size: 16px;
  }
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

const DownloadText = styled.div`
  margin-left: 5px;
  display: none;
`;

const MediaContainer = styled.div`
  margin-bottom: 30px;
  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  height: auto;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  cursor: zoom-in;
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  &:hover {
    ${DownloadText} {
      display: block;
    }
    ${ImageInfoBg} {
      bottom: 0px;
    }
  }
`;

const MediaImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
  transition: 500ms;

  @media ${breakpoint.desktop} {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const AudioImage = styled.img`
  user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
  flex-shrink: 0;
`;

const AudioContainer = styled.div`
  height: auto;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  border-radius: 6px;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const AudioChildContainer = styled.div`
  backdrop-filter: blur(50px);
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  border-radius: 6px;

  @media (min-width: 1201px) {
    padding: 22px 18px;
  }
  @media (max-width: 1200px) {
    padding: 14px 12px;
  }
`;

export default MediaGallery;
