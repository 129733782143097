import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import EmailTextField from '../../../components/common/textfields/EmailTextField';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { updateProfileWithSession } from '../../../apis';
import Space from '../../../components/common/Space';
import FormTextfield from '../../../components/common/textfields/FormTextField';
import CommonText from '../../../components/common/typographies/CommonText';
import CommonButton from '../../../components/common/buttons/CommonButton';
import constants from '../../../constants';
import { breakpoint } from '../../../utils/responsive';

function ContactEmailForm({ add, onClose }) {
  const { session, setSession } = useContext(AppContext);
  const { t, i18n } = useTranslation(['profile', 'signin']);
  const [error, setError] = useState(null);
  const emailRef = useRef();
  const emailOTPRef = useRef();

  const validate = () => {
    let error = {};
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    if (!validEmail.test(emailRef.current.value))
      error.email = t('signin:register.invalid_email');
    if (emailOTPRef.current.value.length != 6)
      error.emailOTP = t('signin:register.invalid_email_otp');
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleSave = async () => {
    if (validate()) {
      const body = {
        contactEmail: emailRef.current.value,
        contactEmailOTP: emailOTPRef.current.value,
      };
      const result = await updateProfileWithSession(body, {
        session,
        setSession,
      });
      if (result.success) {
        if (onClose) onClose();
      } else {
        // set api error
        if (result.error?.code == 'C0003') {
          setError({ emailOTP: t('signin:register.invalid_email_otp') });
        }
      }
    }
  };

  return (
    <StyledMainDiv>
      <CommonText mFontSize="24px" fontSize="30px" fontWeight="600">
        {add
          ? t('profile:button.add_contact_email')
          : t('profile:button.change_contact_email')}
      </CommonText>
      <Space size="50px" />
      <EmailTextField
        errorText={error?.email}
        inputRef={emailRef}
        contactHint={true}
        label={t('signin:register.contact_email_optional')}
        purpose="contact"
        requestKey={
          session?.user?._id
            ? session?.user?._id + '_contact_emailOTP'
            : 'contact_emailOTP'
        }
        requestOTP
      />
      <Space size="20px" />
      <FormTextfield
        inputRef={emailOTPRef}
        errorText={error?.emailOTP}
        label={t('signin:register.email_otp')}
      />
      <Space size="20px" />
      <CommonButton
        desktopWidth={'100%'}
        size="large"
        type={'primary'}
        normalCase={true}
        onClick={handleSave}
      >
        {t('profile:button.save')}
      </CommonButton>
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  @media ${breakpoint.mobile} {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export default ContactEmailForm;
