import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CloseButton from '../components/common/buttons/CloseButton';
import { useNavigate } from 'react-router-dom';
import Space from '../components/common/Space';
import { Box, useMediaQuery } from '@mui/material';
import ImgBrush from '../assets/join2025/miro_renewal_2025_thankyou_brush.svg';
import ImgGiftBgPattern from '../assets/join2025/gift_bg_pattern.png';
import { breakpoint, ResponsiveUI } from '../utils/responsive';
import moment from 'moment';
import Lottie from 'lottie-react';
import animationData from '../assets/animation/thank_you_page_2025.json';
import 'moment-timezone';
import { useQuery } from 'react-query';
import constants from '../constants';
import { getProfile } from '../apis';
import Loading from '../components/common/loading';
import ReactGA from 'react-ga4';

const TitleWithValue = ({ title, value, ...props }) => {
  return (
    <ResponsiveUI
      mobileOffset={-28}
      mobile={
        <TitleWithValueContainer>
          <div>
            <TitleText>{title}</TitleText>
            <ValueText>{value}</ValueText>
          </div>
        </TitleWithValueContainer>
      }
      desktop={
        <Box display={'flex'} flexDirection="column" {...props}>
          <TitleText>{title}</TitleText>
          <ValueText>{value}</ValueText>
        </Box>
      }
    />
  );
};

function getNewExpiryDateDesc(t) {
  const currentDate = moment().tz('Asia/Hong_Kong');
  const year = currentDate.year();
  const isBeforeRenewalEnd = currentDate.isBefore(
    moment(constants.JOIN_2025_END_DAY)
  );

  if (year === 2024) {
    return t('join2025.desc_new_expiry_date_before_2025');
  } else if (year === 2025 && isBeforeRenewalEnd) {
    return t('join2025.desc_new_expiry_date_in_2025_before_end');
  } else {
    return t('join2025.desc_new_expiry_date_in_2025_after_end');
  }
}

function getShippingNoteDesc(t) {
  const currentDate = moment().tz('Asia/Hong_Kong');
  const isBeforeRenewalEnd = currentDate.isBefore(
    moment(constants.JOIN_2025_END_DAY)
  );
  if (isBeforeRenewalEnd) {
    return t('join2025.desc_miro_gift_pack_delivery_before_end');
  } else {
    return t('join2025.desc_miro_gift_pack_delivery_after_end');
  }
}

export default function MiroRegisterSuccess({
  session,
  memberType,
  payment,
  setSession,
  isLateJoin,
}) {
  const { t, i18n } = useTranslation('signin');
  const isDesktop = useMediaQuery(breakpoint.desktop);
  const navigate = useNavigate();
  const { isLoading, isError, data, error } = useQuery(['getProfile'], () =>
    session && session?.accessToken ? getProfile({ session, setSession }) : null
  );

  const reference_number = payment?._id;
  const amount = payment?.item?.amount;
  const date = payment?.updatedAt
    ? moment(payment.updatedAt)
        .tz('Asia/Hong_Kong')
        .locale('en')
        .format('YYYY.MM.DD hh:mma')
    : '';
  const deliveryDate =
    payment?.order?.deliveryDate && payment?.order?.deliveryDate !== 'N/A'
      ? payment.order.deliveryDate.split('/')[i18n.language == 'en' ? 1 : 0]
      : null;


  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      {/* <CommonText fontSize="30px" lineHeight="45px">{t('register.miro_register_success')}</CommonText> */}
      <FlexContainer>
        <InfoContainer>
          <ThankYouContainer>
            <RegSuccessText>
              {t('join2025.miro_register_success2025')}
            </RegSuccessText>
            <RegThankYou>
              <StickerBrush src={ImgBrush} />
              <RegThankYouText>
                {t('join2025.miro_register_thankyou')}
              </RegThankYouText>
            </RegThankYou>
          </ThankYouContainer>
          <DescContainer>
            <OrderInfo>
              <TitleWithValue
                title={t('register.miro_register_amount')}
                value={`HK$${amount}`}
                mr={2.5}
              />
              <TitleWithValue
                title={t('register.miro_register_date')}
                value={date}
                mr={2.5}
              />
              {amount > 0 && (
                <TitleWithValue
                  title={t('register.miro_register_ref_number')}
                  value={reference_number}
                  mr={2.5}
                />
              )}
              {deliveryDate && !isLateJoin && (
                <TitleWithValue
                  title={t('join2025.delivery_data_title')}
                  value={deliveryDate}
                />
              )}
            </OrderInfo>
            <Divider />
            <DescText>
              {getNewExpiryDateDesc(t)}
              <br />
              {((!isLateJoin && deliveryDate) ||
                (isLateJoin &&
                  payment?.order?.deliveryDate == 'Late Enrolment')) && (
                <>
                  {getShippingNoteDesc(t)}
                  <br />
                </>
              )}
              <br />
              {t('join2025.desc_welcome')}
            </DescText>
          </DescContainer>
        </InfoContainer>
        <Space size="40px" />
        <GiftContainer>
          <Space size="40px" />
          <UnlockTitle>{t('join2025.unlock_gift_title')}</UnlockTitle>
          <Space size="4px" />
          <UnlockDesc>{t('join2025.unlock_gift_desc')}</UnlockDesc>
          <Space size="20px" />
          <UnlockBtn
            onClick={() => {
              ReactGA.event('button_click', {
                button_text: 'Thank you_Gift unlock button',
              });
              navigate('/join/gift');
            }}
          >
            {t('join2025.unlock_gift_button')}
          </UnlockBtn>
          <Space size="20px" />
          <LottieContainer>
          <Lottie animationData={animationData} loop={true} />
          </LottieContainer>
          <Space size="40px" />
        </GiftContainer>
        <CloseButton onClick={() => navigate('/', { replace: true })} />
      </FlexContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background-color: #7ec6b9;
  overflow: hidden;
  position: relative;
  padding-top: 80px;
  padding-bottom: 100px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoint.mobile} {
    width: 358px;
  }
  @media (min-width: 833px) {
    width: 673px;
  }
  @media (min-width: 1440px) {
    width: 1200px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1440px) {
    flex-direction: row;
  }
  align-items: start;
`;

const ThankYouContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 48px;
`;

const RegSuccessText = styled.div`
  color: #373737;
  font-family: 'Open Sans';
  font-size: 20px;
  @media (min-width: 833px) {
    font-size: 24px;
  }
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  margin-bottom: 8px;
  text-align: left;
`;

const RegThankYou = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

const RegThankYouText = styled.div`
  position: relative;
  color: #373737;
  font-family: Philosopher;
  font-size: 42px;
  @media (min-width: 833px) {
    font-size: 60px;
  }
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 60px */
  letter-spacing: -3px;
  text-transform: uppercase;
  z-index: 10000;
`;

const StickerBrush = styled.img`
  position: absolute;
  z-index: 0;
  @media ${breakpoint.mobile} {
    bottom: 16px;
    left: 70px;
    width: 202.5px;
  }
  @media (min-width: 833px) {
    width: 269px;
    left: 83px;
    bottom: 10px;
  }
  @media (min-width: 1440px) {
    width: 400px;
    left: 0;
    bottom: 0;
  }
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpoint.mobile} {
    width: 358px;
  }
  @media (min-width: 833px) {
    width: 673px;
  }
  @media (min-width: 1440px) {
    width: 800px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.5;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: row;
  @media ${breakpoint.mobile} {
    flex-wrap: wrap;
  }
`;

const DescText = styled.div`
  color: #373737;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  text-align: left;
`;

const GiftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  border: 2px solid #373737;
  background: url(${ImgGiftBgPattern});
  background-color: white;
`;
const orange = '#eb6d13';
const UnlockTitle = styled.div`
  color: ${orange};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
`;

const UnlockDesc = styled.div`
  color: ${orange};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
`;

const UnlockBtn = styled(Button)`
  && {
    border-radius: 100px;
    background: ${orange};
    height: 50px;
    @media ${breakpoint.mobile} {
      width: 318px;
    }
    @media (min-width: 833px) {
      width: 440px;
    }
    @media (min-width: 1440px) {
      width: 164px;
    }
    color: #f9f9f9;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background-color: #d36211;
    }
  }
`;

const LottieContainer = styled.div`
  height: 300px;
  width: 300px;
`;
const TitleWithValueContainer = styled.div`
  flex: 1 1 50%; /* Each child takes up 50% of the container width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  word-wrap: break-word; /* Break long words and wrap them to the next line */
  word-break: break-all; /* Break words at any character to wrap them to the next line */
  white-space: normal;
  text-align: left;
  &:nth-child(odd) {
    padding-right: 16px; /* Adjust the padding value as needed */
  }
  &:nth-child(1) {
    padding-bottom: 16px;
  }
`;

const ValueText = styled.div`
  color: #373737;
  /* m_TC caption */
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: left;
`;

const TitleText = styled(ValueText)`
  opacity: 0.7;
`;
