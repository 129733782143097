import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import MkvPlayer from '../../../components/MkvPlayer';
import { breakpoint } from '../../../utils/responsive';
if (window.jwplayer)
  window.jwplayer.key = 'nvNka3tbOHB6pWg0gDCvVeRT4EiRZSBQaYLcCA==';

const VideoDisplay = ({ item, handleOnPlay, isSelected }) => {
  return (
    <Container>
      {isSelected && (
        <MkvPlayer
          src={item.type === 'VIDEO' ? item.video : item.streamUrl}
          handleOnPlay={handleOnPlay}
          isLive={false}
          title={item.filename}
          videoId={item._id}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 0;
  .jw-icon-rewind {
    display: none;
  }
  .jw-display-icon-rewind {
    visibility: hidden;
  }

  @media ${breakpoint.mobile} {
    margin-top: 80%;
  }

  @media ${breakpoint.tablet} {
    margin-top: 45%;
  }

  @media ${breakpoint.desktop} {
    margin-top: 5%;
  }
`;

export default VideoDisplay;
