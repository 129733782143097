import styled from 'styled-components';
import OrderDetailInfo from '../pageComponents/profile/order/OrderDetailInfo';
import OrderInfo from '../pageComponents/profile/order/OrderInfo';
import { ResponsiveUI } from '../utils/responsive';

export default function ProfileOrderDetail() {
  const _renderContent = (isMobile) => {
    return (
      <StyleContentDiv>
        <StyleFormDiv>
          <OrderDetailInfo />
        </StyleFormDiv>
      </StyleContentDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyleContentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StyleFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
