import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMediaSeriesById } from '../apis';
import Loading from '../components/common/loading';
import { AppContext } from '../AppContext';
import MediaGallery from '../pageComponents/mediaSeriesDetail/MediaGallery';
import CommonBottomBar from '../components/common/navbar/CommonBottomBar';
import styled from 'styled-components';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import TitleGroup from '../components/common/typographies/TitleGroup';
import colors from '../utils/colors';
import CommonDivider from '../components/common/divider/CommonDivider';
import StickerLineTop from '../assets/resource_line_seriesDetail_top.png';
import StickerLineTopMobile from '../assets/resource_line_seriesDetail_top_mobile.png';
import StickerLineBottom from '../assets/resource_line_seriesDetail_bottom.png';
import StickerLineBottomMobile from '../assets/resource_line_seriesDetail_bottom_mobile.png';
import { ResponsiveUI, breakpoint } from '../utils/responsive';
import Space from '../components/common/Space';
import { Box, Grid, ImageList, useMediaQuery, useTheme } from '@mui/material';
import { styled as styledMuiComponents } from '@mui/material/styles';
import Title from '../pageComponents/mediaSeriesDetail/Title';
import MoreContentItem from '../pageComponents/mediaSeriesDetail/MoreContentItem';
import { useTranslation } from 'react-i18next';
import AgreementDialog from '../pageComponents/mediaSeries/AgreementDialog';
import ReactGA from 'react-ga4';
import BackArrowButton from '../components/common/buttons/BackArrowButton';

const MediaSeriesDetail = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const { session } = useContext(AppContext);
  const { pathname } = useLocation();
  const containerRef = useRef();
  const navigate = useNavigate();
  const { isLoading, isError, data, error } = useQuery(
    ['getMediaSeriesById', id],
    () => getMediaSeriesById(id)
  );
  const disableRightClick = useCallback((event) => {
    event.preventDefault();
  }, []);

  const [isOpenLightbox, setIsOpenLightbox] = useState(false); // false, or opened index
  useEffect(() => {
    function deleteCookie(name) {
      document.cookie = `${name}=; Max-Age=0; path=/; domain=.mirrorweare.com`;
    }
    deleteCookie('CloudFront-Key-Pair-Id');
    deleteCookie('CloudFront-Policy');
    deleteCookie('CloudFront-Signature');

    if (session && session['CloudFront-Policy']) {
      document.cookie = `CloudFront-Policy=${session['CloudFront-Policy']}; domain=.mirrorweare.com; path=/media; secure; SameSite=none`;
    }
    if (session && session['CloudFront-Key-Pair-Id']) {
      document.cookie = `CloudFront-Key-Pair-Id=${session['CloudFront-Key-Pair-Id']}; domain=.mirrorweare.com; path=/media; secure; SameSite=none`;
    }

    if (session && session['CloudFront-Signature']) {
      document.cookie = `CloudFront-Signature=${session['CloudFront-Signature']}; domain=.mirrorweare.com; path=/media; secure; SameSite=none`;
    }
  }, [session]);

  useEffect(() => {
    window.addEventListener('contextmenu', disableRightClick);
    return () => window.removeEventListener('contextmenu', disableRightClick);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
    }
  }, [containerRef, pathname]);

  useEffect(() => {
    if (data?.data?.nameZh) {
      ReactGA.event('album_view', {
        album_title: data?.data?.nameZh,
      });
    }
  }, [data?.data?.nameZh]);
  const theme = useTheme();
  const matchTwoItem = useMediaQuery(theme.breakpoints.up('sm'));
  const matchThreeItem = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoint.mobile);

  if (isLoading) {
    return <Loading></Loading>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const getNumberOfMoreContentItem = () => {
    if (matchThreeItem) {
      return 3;
    }
    if (matchTwoItem) {
      return 2;
    }
    return 1;
  };

  return (
    <Container ref={containerRef} isOpenLightbox={isOpenLightbox !== false}>
      <BackArrowButton onClick={() => navigate('/media-series')} />
      <ResponsiveUI
        mobile={<Space size="80px" />}
        desktop={<Space size="110px" />}
      />
      <DetailContainer>
        <Title
          data={data?.data}
          style={{ position: 'relative', zIndex: 1 }}
        ></Title>
        <ResponsiveUI
          desktop={
            <Line src={StickerLineTop} style={{ width: '350px', top: -30 }} />
          }
          mobile={<Line src={StickerLineTopMobile} style={{ top: -30 }} />}
        />

        <div style={{ position: 'relative', zIndex: 1 }}>
          <MediaGallery
            data={data?.data?.medias}
            isOpenLightbox={isOpenLightbox}
            setIsOpenLightbox={setIsOpenLightbox}
          />
        </div>

        <ResponsiveUI
          desktop={<Space size="80px"></Space>}
          mobile={<Space size="60px"></Space>}
        />
        <div style={{ position: 'relative' }} className="sticker_line_bottom">
          <BottomStickerLineWrapper>
            <ResponsiveUI
              desktop={
                <Line
                  src={StickerLineBottom}
                  style={{ position: 'relative', left: -50, width: 360 }}
                  alt="sticker_line_bottom"
                />
              }
              mobile={
                <Line
                  src={StickerLineBottomMobile}
                  style={{ position: 'relative', left: -16, width: 280 }}
                  alt="sticker_line_bottom"
                />
              }
            />
          </BottomStickerLineWrapper>

          <CommonDivider style={{ borderColor: 'black' }} />
        </div>
      </DetailContainer>
      <div className="more_content_section">
        <ResponsiveUI
          desktop={<Space size="80px"></Space>}
          mobile={<Space size="60px"></Space>}
        />
        <div style={{ position: 'relative', zIndex: 0 }}>
          <TitleGroup
            zhTitle={i18n.language === 'zh' && '更多內容'}
            enTitle="More content"
            color={colors.black}
          />
        </div>
        <StyledImageList
          cols={getNumberOfMoreContentItem()}
          gap={isMobile ? 30 : 60}
        >
          {data?.data.more_content.map((item, idx) => {
            return idx < getNumberOfMoreContentItem() ? (
              <MoreContentItem item={item} />
            ) : null;
          })}
        </StyledImageList>
      </div>
      <CommonBottomBar theme="black" bgColor="greyBlue" />
      <AgreementDialog />
    </Container>
  );
};

const BottomStickerLineWrapper = styledMuiComponents(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: 30,
    [theme.breakpoints.down('sm')]: {
      top: 70,
    },
  };
});

const StyledImageList = styledMuiComponents(ImageList)(({ theme }) => {
  return {
    maxWidth: 1920,
    margin: 'auto',
    padding: 60,
    [`@media ${breakpoint.mobile}`]: {
      padding: 30,
      paddingBottom: 120,
    },
    paddingBottom: 120,
  };
});

const Line = styled.img`
  user-drag: none;
  position: absolute;
  right: 0px;
  z-index: 0;
`;

const Container = styled.div`
  background-color: #b7c2cd;
  position: relative;

  // on top of scroll indicator
  z-index: ${(props) => props.isOpenLightbox && '8889'};
`;

const DetailContainer = styled.div`
  padding: 0 50px;
  @media ${breakpoint.mobile} {
    padding: 0 16px;
  }
  padding-bottom: 0;
`;

export default MediaSeriesDetail;
