import React, { useState, useRef, useEffect } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import RadioPlayer from '../../mediaSeriesDetail/MediaItem/RadioPlayer';
import ReactGA from 'react-ga4';
import PlayIcon from '../../../assets/resource_radioPlay.svg';
import PauseIcon from '../../../assets/resource_radioPause.svg';
import styled from 'styled-components';
import CommonText from '../../../components/common/typographies/CommonText';
import {
  breakpoint,
  ResponsiveUI,
  WindowSize,
} from '../../../utils/responsive';
import RecordingAsset from '../../../assets/revamp2025/ic-message-to-miro.png';
import BottomRibbon from '../../../assets/revamp2025/ic-message-to-miro-line.png';
import MemberImage1 from '../../../assets/daycounter/01.png';
import MemberImage2 from '../../../assets/daycounter/02.png';
import MemberImage3 from '../../../assets/daycounter/03.png';
import MemberImage4 from '../../../assets/daycounter/04.png';
import MemberImage5 from '../../../assets/daycounter/05.png';
import MemberImage6 from '../../../assets/daycounter/06.png';
import MemberImage7 from '../../../assets/daycounter/07.png';
import MemberImage8 from '../../../assets/daycounter/08.png';
import MemberImage9 from '../../../assets/daycounter/09.png';
import MemberImage10 from '../../../assets/daycounter/10.png';
import MemberImage11 from '../../../assets/daycounter/11.png';
import MemberImage12 from '../../../assets/daycounter/12.png';
import { useMediaQuery } from '@mui/material';
import HomeStar from '../asset/Star';

const memberImages = [
  MemberImage1,
  MemberImage2,
  MemberImage3,
  MemberImage4,
  MemberImage5,
  MemberImage6,
  MemberImage7,
  MemberImage8,
  MemberImage9,
  MemberImage10,
  MemberImage11,
  MemberImage12,
];

const MemberRecording = ({ message = {} }) => {
  const { audio, name, thumbnail } = message;
  const thumbnailNumber = parseInt(thumbnail.match(/(\d+)/)[0]);
  const thumbnailIdx = thumbnailNumber ? thumbnailNumber - 1 : 1;
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);

  const audioRef = useRef({});

  return (
    <Container>
      <TopAssetContainer>
        <HomeStar width={28} height={28} color="#FFA42C" />
        <StyledRecording alt="recording" src={RecordingAsset} />
      </TopAssetContainer>
      <ResponsiveUI
        desktop={<></>}
        mobile={
          <Thumbnail>
            <img src={memberImages[thumbnailIdx]} alt="thumbnail" />
          </Thumbnail>
        }
        desktopBreakpoint={breakpoint[1024]}
      />

      <PlayerContainer>
        <ResponsiveUI
          desktop={
            <Thumbnail>
              <img src={memberImages[thumbnailIdx]} alt="thumbnail" />
            </Thumbnail>
          }
          mobile={<></>}
          desktopBreakpoint={breakpoint[1024]}
        />
        <ContentContainer>
          <MessageFromContainer>
            <CommonText
              type="primary"
              fontSize="30px"
              fontSizeFor1024="42px"
              fontWeight="400"
              color="#FF5C00"
            >
              Message to MIRO
            </CommonText>
            <CommonText fontSize="20px" fontWeight="400" type="primary">
              From {name}
            </CommonText>
          </MessageFromContainer>
          <StyledPlayer
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0)',
              boxShadow: 'none',
              padding: 0,
            }}
            ref={(el) => {
              if (el) {
                audioRef.current = el;
              } else {
                delete audioRef.current;
              }
            }}
            src={audio}
            // src={
            //   'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3'
            // }
            showJumpControls={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            customControlsSection={[]}
            customProgressBarSection={[
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_LEFT_TIME,
            ]}
            customIcons={{
              play: (
                <IconContainer
                  className="button-container"
                  onClick={() => {
                    console.log('Homepage_Audio play button');
                    ReactGA.event('button_click', {
                      button_text: 'Homepage_Audio play button',
                    });
                    ReactGA.event('click_message_to_miro', {
                      button_text: 'Homepage_Audio play button',
                    });
                  }}
                >
                  <Icon src={PlayIcon} alt="play" />
                </IconContainer>
              ),
              pause: (
                <IconContainer className="button-container">
                  <Icon src={PauseIcon} alt="pause" />
                </IconContainer>
              ),
            }}
          />{' '}
        </ContentContainer>
      </PlayerContainer>

      <StyledBottomRibbon alt="bottom ribbon" src={BottomRibbon} />
    </Container>
  );
};

const TopAssetContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;
  right: 31px;
  top: -125px;
  gap: 10px;
  @media ${breakpoint.desktop} {
    top: -143px;
  }
  @media ${breakpoint[1024]} {
    flex-direction: row;
    align-items: center;
    right: -10px;
    top: -82px;
  }
`;

const StyledBottomRibbon = styled.img`
  position: absolute;
  // transform: translate(10px, -53px);
  right: 70px;
  bottom: -53px;
  width: 190px;
  @media ${breakpoint[1024]} {
    bottom: 0;
    right: 0;
    width: 260px;
    transform: translate(10%, 40%);
  }
`;
const StyledRecording = styled.img`
  transform: rotate(-20deg);
  width: 100px;
  height: 100px;

  @media ${breakpoint[1024]} {
    right: -25px;
    top: 50px;
    width: 150px;
    height: 150px;
  }
`;

const Container = styled.div`
  position: relative;

  padding-top: 100px;

  margin: auto;
  margin-top: 200px;
  margin-bottom: 80px;
  padding: 0 20px;
  max-width: calc(540px + 20px + 20px);

  @media ${breakpoint.desktop} {
    padding: 0;
  }

  @media ${breakpoint[1024]} {
    margin: 100px auto 200px;
    max-width: 1350px;
  }

  @media ${breakpoint.extraLargeDesktop} {
    margin-bottom: 150px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  @media ${breakpoint.largeDesktop} {
    flex-direction: row;
    gap: 50px;
  }
`;

const MessageFromContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: start;
  gap: 8px;
`;

const Thumbnail = styled.div`
  width: 114px;
  position: relative;
  flex-shrink: 0;

  position: absolute;
  margin-left: 33px;

  @media ${breakpoint.desktop} {
    position: relative;
    margin-left: 30px;
  }
  @media ${breakpoint[1024]} {
    width: 180px;
    top: 50px;
    margin-left: 0;
  }

  @media ${breakpoint.extraLargeDesktop} {
    margin-left: 64.5px;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    bottom: 0;
  }
`;

const IconContainer = styled.div`
  background: #ff5c00;
  border-radius: 100%;
  width: 100%;
  height: 100%;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 7px;

  @media ${breakpoint[1024]} {
    width: 22px;
    height: 22px;
    margin-bottom: 10px;
    margin-left: 2px;
  }
`;

const PlayerContainer = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 20px;
  @media ${breakpoint[1024]} {
    padding: 50px 40px 50px 60px;
    gap: 40px;
  }
  @media ${breakpoint.largeDesktop} {
    padding-right: 115px;
`;
const StyledPlayer = styled(AudioPlayer)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  .rhap_play-pause-button {
    font-size: 42px;
    width: 42px;
    height: 42px;
    @media ${breakpoint[1024]} {
      font-size: 48px;
      width: 48px;
      height: 48px;
    }
  }
  .rhap_button-clear:hover {
    opacity: 1;
    .button-container {
      background: #e55300;
    }
  }
  .rhap_progress-bar {
    background-color: #ffa72280;
    height: 4px;
  }
  .rhap_download-progress {
    background-color: #ffa72280;
  }

  .rhap_progress-filled {
    background-color: #ffa722;
  }
  .rhap_progress-indicator {
    background: #ffa722;
    width: 16px;
    height: 16px;
    top: -6px;
    margin-left: -8px;
    box-shadow: none;
  }

  .rhap_header {
    margin-bottom: 4px;
  }

  .rhap_progress-section {
    flex-wrap: wrap;
    gap: 20px;
    @media ${breakpoint[1024]} {
      gap: 24px;
    }

    .rhap_progress-container {
      align-items: center;
    }

    .rhap_main-controls {
      width: 100%;
      justify-content: start;
      @media ${breakpoint[1024]} {
        width: auto;
      }
    }
  }

  .rhap_stacked .rhap_controls-section {
    margin-top: 0px;
  }
  .rhap_time {
    color: black;
    opacity: 0.3;
    font-size: 18px;
  }
`;
export default MemberRecording;
