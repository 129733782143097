import styled from 'styled-components';
import Space from '../../components/common/Space';
import CommonButton from '../../components/common/buttons/CommonButton';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import DeliveryAddress from '../miroregister/DeliveryAddress';
import { useState, useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../AppContext';
import { updateUser } from '../../apis';
import moment from 'moment';
import constants from '../../constants';

const Step2 = ({ handlePageChange, setSessionExpired }) => {
  const { t } = useTranslation('signin');
  const { session, setSession, lateCode } = useContext(AppContext);
  const [address, setAddress] = useState(session?.user?.addresses[0]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validate = () => {
    let error = {};
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleNext = async (back = false, optin = true) => {
    if (!loading && (back || validate())) {
      setLoading(true);
      const body = {
        addresses: [
          {
            ...address,
            date: optin
              ? !!lateCode
                ? constants.LATE_JOIN_DATE_OPTION_ID
                : address.date
              : null,
          },
        ],
      };
      console.log(body);
      let result = null;
      result = await updateUser(body);
      console.log(result);
      if (result?.success) {
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem('session', JSON.stringify(newSession));
        setSession(newSession);
        if (back) handlePageChange('root');
        else handlePageChange('step3');
      } else {
        // set api error
        if (result.error?.code == 'E0002') {
          setSessionExpired(true);
        } else if (back) handlePageChange('root');
      }
      setLoading(false);
    }
  };

  return (
    <DeliveryAddress
      step=" 2/3"
      canChooseDate={!lateCode}
      session={session}
      handleNext={handleNext}
      address={address}
      setAddress={setAddress}
    />
  );
};

export default Step2;
