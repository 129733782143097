import { register, updateTmpUser } from '../../apis';
import styled from 'styled-components';
import CommonButton from '../../components/common/buttons/CommonButton';
import FormTextField from '../../components/common/textfields/FormTextField';
import EmailTextField from '../../components/common/textfields/EmailTextField';
import PasswordTextField from '../../components/common/textfields/PasswordTextField';
import { useState, useRef, useContext } from 'react';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import Space from '../../components/common/Space';
import { AppContext } from '../../AppContext';
import constants from '../../constants';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Step1({ handlePageChange, setSessionExpired }) {
  const { t } = useTranslation(['common', 'signin']);
  const { session, setSession } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [error, setError] = useState(null);
  const recaptchaRef = useRef(null);
  const usernameRef = useRef();
  const emailRef = useRef();
  const emailOTPRef = useRef();
  const passwordRef = useRef();
  const retypePasswordRef = useRef();
  const user = session?.user;

  const onReCaptchaChange = (value) => {
    setRecaptcha(value);
  };

  const validate = () => {
    let error = {};
    if (
      usernameRef.current.value.length < 2 ||
      usernameRef.current.value.length > 50
    )
      error.username = t('signin:register.invalid_username');
    const validEmail = new RegExp(constants.EMAIL_REGEX);
    if (!validEmail.test(emailRef.current.value))
      error.email = t('signin:register.invalid_email');
    if (emailOTPRef.current.value.length != 6)
      error.emailOTP = t('signin:register.invalid_email_otp');
    const validPassword = new RegExp(constants.PASSWORD_REGEX);
    if (!validPassword.test(passwordRef.current.value))
      error.password = t('signin:register.invalid_password');
    else if (passwordRef.current.value != retypePasswordRef.current.value)
      error.retypePassword = t('signin:register.invalid_retype_password');
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const handleRegister = async () => {
    if (!loading && validate()) {
      setLoading(true);
      if (user) {
        // update existing tmp user
        const body = {
          username: usernameRef.current.value,
          email: emailRef.current.value,
          emailOTP: emailOTPRef.current.value,
          password: passwordRef.current.value,
          retypePassword: retypePasswordRef.current.value,
        };
        console.log(body);
        const result = await updateTmpUser(body);
        console.log(result);
        if (result.success) {
          const newSession = {
            ...session,
            user: result.data,
          };
          localStorage.setItem('session', JSON.stringify(newSession));
          setSession(newSession);
          handlePageChange('step2');
        } else {
          // set api error
          if (result.error?.code == 'E0002') {
            setSessionExpired(true);
          } else if (result.error?.code == 'C0001') {
            setError({ username: t('signin:register.used_username') });
          } else if (result.error?.code == 'C0002') {
            setError({ email: t('signin:register.used_email') });
          } else if (result.error?.code == 'C0003') {
            setError({ emailOTP: t('signin:register.invalid_email_otp') });
          }
        }
      } else {
        const body = {
          username: usernameRef.current.value,
          email: emailRef.current.value,
          emailOTP: emailOTPRef.current.value,
          password: passwordRef.current.value,
          retypePassword: retypePasswordRef.current.value,
          isTmpUser: true,
          recaptcha,
        };
        console.log(body);
        const result = await register(body);
        console.log(result);
        if (result.success) {
          localStorage.setItem('session', JSON.stringify(result.data));
          setSession(result.data);
          handlePageChange('step2');
        } else {
          // set api error
          if (result.error?.code == 'C0001') {
            setError({ username: t('signin:register.used_username') });
          } else if (result.error?.code == 'C0002') {
            setError({ email: t('signin:register.used_email') });
          } else if (result.error?.code == 'C0003') {
            setError({ emailOTP: t('signin:register.invalid_email_otp') });
          }
        }
        recaptchaRef.current.reset();
      }
      setLoading(false);
    }
  };
  return (
    <Container>
      <CommonText lineHeight="140%">
        {t('signin:register.step') + '1/6'}
      </CommonText>
      <Space size="20px" />
      <FormTextField
        defaultValue={user?.username}
        errorText={error?.username}
        inputRef={usernameRef}
        label={t('signin:register.username')}
      />
      <Space size="20px" />
      <EmailTextField
        defaultValue={user?.email}
        errorText={error?.email}
        inputRef={emailRef}
        label={t('signin:register.email')}
        purpose="miro"
        requestKey="miro_emailOTP"
        requestOTP
      />
      <Space size="20px" />
      <FormTextField
        inputRef={emailOTPRef}
        errorText={error?.emailOTP}
        label={t('signin:register.email_otp')}
      />
      <Space size="20px" />
      <PasswordTextField
        inputRef={passwordRef}
        errorText={error?.password}
        helperText={t('common:password_hint')}
        label={t('signin:register.password')}
      />
      <Space size="20px" />
      <PasswordTextField
        inputRef={retypePasswordRef}
        errorText={error?.retypePassword}
        label={t('signin:register.retype_password')}
      />
      <Space size="20px" />
      {!user && (
        <ReCAPTCHA
          sitekey={constants.RECAPTCHA}
          onChange={onReCaptchaChange}
          ref={recaptchaRef}
        />
      )}
      <Space size="20px" />
      <CommonButton type={'primary'} onClick={handleRegister}>
        {t('signin:register.next')}
      </CommonButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;
