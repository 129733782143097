import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  refreshToken,
  setUpRefreshTokenLogic,
  validateRenewMiroCode,
} from './apis';
import { useRef } from 'react';
import getMemberType from './utils/getMemberType';
import moment from 'moment';
import constants from './constants';

const miroCodeName = 'miro-exclusive-code';

export const AppContext = React.createContext();
export function AppProvider(props) {
  const [init, setInit] = useState(false);
  const [session, setSession] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [disableMiroReg, setDisableMiroReg] = useState(true);
  const [lateCode, setLateCode] = useState(null);
  const effectRan = useRef(false);
  const { i18n } = useTranslation();

  const removeLateCode = useCallback(() => {
    localStorage.removeItem(miroCodeName);
  }, []);

  const value = React.useMemo(() => {
    return {
      init,
      session,
      setSession,
      memberType,
      disableMiroReg,
      lateCode,
      setLateCode,
      removeLateCode,
    };
  }, [init, session, setSession, memberType, lateCode, setLateCode]);

  console.log(
    '🚀 ~ AppProvider ~ session:',
    !!session?.user,
    session?.user?.miroDayCount
  );
  useEffect(() => {
    setMemberType(getMemberType(session?.user));
  }, [session?.user]);

  useEffect(() => {
    if (lateCode) localStorage.setItem(miroCodeName, lateCode);
  }, [lateCode]);

  useEffect(() => {
    const initFunction = async () => {
      setUpRefreshTokenLogic(setSession);
      // load localstorage to state
      const sessionJSON = localStorage.getItem('session', '');
      const session = JSON.parse(sessionJSON);
      let isLatestMiro = false;
      console.log('check session', session);
      if (
        session?.user?.memberType == 'TMP' &&
        new Date(session?.user?.accountExpiresAt) < new Date()
      ) {
        // clear session if tmp user expired
        console.log('clear session if tmp user expired');
        localStorage.removeItem('session');
      } else if (session?.refreshToken) {
        const result = await refreshToken(session?.refreshToken);
        if (result.success) {
          localStorage.setItem('session', JSON.stringify(result.data));
          setMemberType(getMemberType(result.data.user));
          setSession(result.data);
          isLatestMiro =
            result.data?.user?.memberType === 'MIRO' &&
            !moment(result.data?.user?.memberExpiresAt).isBefore(
              moment(constants.MEMBERSHIP_EXPIRY_DAY),
              'year'
            );
        } else {
          localStorage.removeItem('session');
        }
      }

      // load localstorage late miro code to state
      const tCode = localStorage.getItem(miroCodeName, '');
      if (tCode) {
        const result = await validateRenewMiroCode(tCode);
        if (result.success) {
          if (isLatestMiro) {
            localStorage.removeItem(miroCodeName);
            setLateCode(null);
          } else {
            setLateCode(tCode);
          }
        } else {
          localStorage.removeItem(miroCodeName);
          setLateCode(null);
        }
      }

      i18n.changeLanguage(localStorage.getItem('language', 'zh'));
      setInit(true);
    };
    console.log(
      '🚀 ~ file: AppContext.js:45 ~ useEffect ~ ignore:',
      effectRan.current
    );
    if (!effectRan.current) {
      initFunction();
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}
