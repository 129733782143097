import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { listActivities } from '../apis';
import styled from 'styled-components';
import CommonNavBar from '../components/common/navbar/CommonNavBar';
import MainSection from '../pageComponents/activity/MainSection';
import ActivitySection from '../pageComponents/activity/ActivitySection';
import CommonBottomBar from '../components/common/navbar/CommonBottomBar';
import Loading from '../components/common/loading';
import { AppContext } from '../AppContext';
import MetaTag from '../utils/MetaTag';
import { breakpoint, ResponsiveUI } from '../utils/responsive';

export default function Activity() {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('desc');
  const { session } = useContext(AppContext);
  const { isLoading, isFetching, isError, data, error, refetch } = useQuery(
    ['listActivities'],
    () => listActivities(search, sort)
  );
  useEffect(() => {
    console.log('isRefetching');
    refetch();
  }, [search, sort]);

  if (isLoading || !data) {
    return <Loading></Loading>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <StyledMainDiv>
      <MetaTag page="activity" />
      <CommonNavBar theme="black" />
      <MainSection></MainSection>

      <StyledActiviteisDiv>
        <ActivitySection
          sort={sort}
          setSort={setSort}
          search={search}
          setSearch={setSearch}
          data={data.data}
          isFetching={isFetching}
        ></ActivitySection>
      </StyledActiviteisDiv>

      <StyledSpaceDiv></StyledSpaceDiv>

      <CommonBottomBar theme="black" bgColor="purple" />
    </StyledMainDiv>
  );
}

const StyledMainDiv = styled.div`
  background: #c3a8ff;
  min-height: 100vh;
`;

const StyledSpaceDiv = styled.div`
  height: 60px;
`;

const StyledActiviteisDiv = styled.div`
  background: #c3a8ff;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px;
  @media ${breakpoint.desktop} {
    padding: 0 40px;
  }
  @media ${breakpoint.largeDesktop} {
    padding: 0 80px;
  }
`;
