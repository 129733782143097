import { enrollActivity } from '../../../apis';
import PhoneTextField from '../../../components/common/textfields/PhoneTextField';
import FormTextField from '../../../components/common/textfields/FormTextField';
import FormSelect from '../../../components/common/selects/FormSelect';
import { useState, useRef, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Space from '../../../components/common/Space';
import CommonButton from '../../../components/common/buttons/CommonButton';
import constants from '../../../constants';
import ReactGA from 'react-ga4';
import CheckBox from '../../../components/common/checkboxs/Checkbox';
import CommonText from '../../../components/common/typographies/CommonText';
import { breakpoint, ResponsiveUI } from '../../../utils/responsive';
import ReCAPTCHA from 'react-google-recaptcha';
import { MenuItem } from '@mui/material';

export default function ApplyForm({
  activity,
  tncPath,
  eventname,
  setRegisterResult,
  participant,
  disabled,
  id,
}) {
  const { t, i18n } = useTranslation(['common', 'activity']);
  const { session, setSession } = useContext(AppContext);
  const hkIdRef = useRef();
  // const [type, setType] = useState(null);
  const [phoneInput, setPhoneInput] = useState(null);
  const [email, setEmail] = useState(
    session.user.contactEmail || session.user.email || null
  );
  const [question, setQuestion] = useState(null);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const recaptchaRef = useRef(null);
  const fullname = session.user.lastName + ' ' + session.user.firstName;

  const validate = () => {
    let error = {};
    if (agreeCheckbox === false) {
      error.agreement = t('activity:agreementCheck');
    }
    if (email === null || email === '') {
      error.email = t('activity:input_email');
    } else {
      const validEmail = new RegExp(constants.EMAIL_REGEX);
      if (!validEmail.test(email)) {
        error.email = t('activity:invalid_email');
      }
    }
    if (phoneInput === null || phoneInput === '') {
      error.phone = t('activity:input_phone');
    } else {
      const validPhone = new RegExp(constants.PHONE_REGEX);
      if (!validPhone.test(phoneInput)) {
        error.phone = t('activity:invalid_phone');
      }
    }
    if (question === null && activity.showQuestion) {
      error.question = t('activity:input_question');
    }
    if (!recaptcha) {
      error.recaptcha = t('activity:missing_recaptcha');
      setError(error);
      setRecaptcha(null);
    }
    // if (hkIdRef.current.value === null || hkIdRef.current.value === ""){
    //     error.hkid = t('activity:empty_hkid');
    // } else{
    //     const validHkId = new RegExp(constants.ID_FIRST_FOUR_REGEX);
    //     if (!(validHkId.test(hkIdRef.current.value))) {
    //         console.log("not pass, format")
    //         error.hkid = t('activity:invalid_hkid');
    //     }
    // }
    setError(error);
    if (Object.keys(error).length === 0) return true;
    else return false;
  };

  const onSubmitHandler = async () => {
    // submit if no error
    if (!loading && validate()) {
      const body = {
        memberNo: session.user.memberNo,
        name: fullname,
        email,
        question: question,
        phone: phoneInput,
        recaptcha,
      };
      setLoading(true);
      const result = await enrollActivity(id, body);
      if (result.success == true) {
        setRegisterResult(true);
      }
      setLoading(false);
    }
    ReactGA.event('activity_join', {
      param: eventname,
    });
  };
  return (
    <StyledDiv style={{ opacity: disabled ? 0.5 : 1 }}>
      <Container>
        <div style={{ textAlign: 'left' }}>
          <CommonText mFontSize="14px">{t('activity:fullname')}</CommonText>
          <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
            {fullname}
          </CommonText>
        </div>
        <div style={{ textAlign: 'left' }}>
          <CommonText mFontSize="14px">{t('activity:miroMemberId')}</CommonText>
          <CommonText mFontSize="16px" fontSize="20px" lineHeight="40px">
            {session.user.memberNo}
          </CommonText>
        </div>
        <FormTextField
          onChange={(event) =>
            setEmail(event.target.value.toLowerCase().replace(' ', ''))
          }
          disabled={disabled}
          value={participant?.email || email}
          errorText={error?.email}
          placeholder={t('activity:enter')}
          label={t('activity:email')}
        />
        <PhoneTextField
          onChange={(value) => setPhoneInput(value)}
          disabled={disabled}
          value={participant?.phone || phoneInput}
          errorText={error?.phone}
          placeholder={t('activity:enter')}
          label={t('activity:phone')}
        />
        {/* <FormTextField inputRef={hkIdRef} errorText={error?.hkid} label={t("activity:identityNum")} /> */}
      </Container>
      {activity.showQuestion && (
        <div style={{ width: '100%', textAlign: 'left' }}>
          <Space size="24px"></Space>
          <FormTextField
            onChange={(event) => setQuestion(event.target.value)}
            value={participant?.question || question}
            errorText={error?.question}
            placeholder={t('activity:enter')}
            disabled={disabled}
            label={
              i18n.language == 'zh' ? activity.questionZh : activity.questionEn
            }
          />
        </div>
      )}
      <Space size="24px" />
      <StyledStatementDiv>
        {/* <FormSelect label={t('activity:type')} name="type" errorText={error?.type} onChange={(event) => setType(event.target.value)} value={type}>
                    <MenuItem value="HKAATA">香港動物輔助治療協會</MenuItem>
                    <MenuItem value="Cookie Smiles">Cookie Smiles</MenuItem>
                    <MenuItem value="HKFWC">香港婦女中心協會</MenuItem>
                </FormSelect> */}
        <Space size="24px"></Space>
        <CheckBox
          onClick={() => setAgreeCheckbox(!agreeCheckbox)}
          isChecked={disabled || agreeCheckbox}
          disabled={disabled}
        >
          <Trans
            t={t}
            i18nKey="activity:agreement"
            values={{ eventname: eventname, activity_id: activity._id }}
            components={[
              <a
                target="_blank"
                style={{ color: '#FF5C00' }}
                key={0}
                href="https://mirrorweare.com/privacy-terms"
                rel="noreferrer"
              >
                個人資料收集聲明
              </a>,
              tncPath && (
                <a
                  target="_blank"
                  style={{ color: '#FF5C00' }}
                  key={1}
                  href={tncPath}
                  rel="noreferrer"
                >
                  條款及條件
                </a>
              ),
            ]}
          />
        </CheckBox>
        {error?.agreement && (
          <CommonText color="#FF5C00">{error.agreement}</CommonText>
        )}
      </StyledStatementDiv>
      <Space size="24px"></Space>
      {!disabled && (
        <>
          <div>
            <ReCAPTCHA
              sitekey={constants.CONTACT_US_RECAPTCHA}
              onChange={(value) => setRecaptcha(value)}
              ref={recaptchaRef}
            />
            {error?.recaptcha && (
              <CommonText
                fontSize="12px"
                style={{ textAlign: 'left' }}
                color="#FF5C00"
              >
                {error.recaptcha}
              </CommonText>
            )}
          </div>
          <Space size="24px"></Space>
          <ResponsiveUI
            mobile={
              <div>
                {!loading && (
                  <CommonButton
                    width="300px"
                    onClick={() => onSubmitHandler()}
                    size={'large'}
                    type={'primary'}
                  >
                    {t('activity:submit')}
                  </CommonButton>
                )}
                {loading && (
                  <CommonButton disabled width="300px" type={'primary'}>
                    {t('activity:submit')}
                  </CommonButton>
                )}
              </div>
            }
            desktop={
              <div>
                {!loading && (
                  <CommonButton
                    onClick={() => onSubmitHandler()}
                    size={'large'}
                    type={'primary'}
                  >
                    {t('activity:submit')}
                  </CommonButton>
                )}
                {loading && (
                  <CommonButton disabled size={'large'} type={'primary'}>
                    {t('activity:submit')}
                  </CommonButton>
                )}
              </div>
            }
          />
        </>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  @media ${breakpoint.desktop} {
    padding: 0px 50px;
    max-width: 1364px;
    margin: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  @media ${breakpoint.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 38px;
    column-gap: 25px;
    max-width: 1264px;
  }
`;

const StyledStatementDiv = styled.div`
  width: 100%;
  @media ${breakpoint.desktop} {
    max-width: 1264px;
  }
`;
