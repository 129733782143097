import styled from "styled-components";
import CommonCloseDialog from "../../components/common/dialogs/CommonCloseDialog";
import CommonText from "../../components/common/typographies/CommonText";
import CommonButton from "../../components/common/buttons/CommonButton";
import { isNullOrBlank } from "../../utils/common";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../../assets/download.svg";
import CheckBox from "../../components/common/checkboxs/Checkbox";
import { useState, useEffect, useRef } from "react";
import SelectTag from "../../components/common/tags/SelectTag";
import { downloadMedia } from "../../apis";
import _ from "lodash";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { downloadMediaImage } from "../../utils/download";
import ReactGA from "react-ga4";
import { styled as styledMuiComponents } from '@mui/material/styles'
import { StyledMediaCloseDialog } from "../../components/common/dialogs/MediaDialog";

const DownloadDialog = ({ data, isOpenDialog, setIsOpenDialog }) => {
  const { t, i18n } = useTranslation("mediaSeriesDetail");
  const [tandcChecked, setTandcChecked] = useState(false);
  const [imageSize, setImageSize] = useState();

  useEffect(() => {
    if (data?.images && data?.images.length > 0) {
      setImageSize(data?.images[0].mediaSize);
    }
  }, [isOpenDialog]);

  const handleDownload = async () => {
    const selectedImage = _.find(data?.images, ["mediaSize", imageSize]);
    ReactGA.event("photo_download_size", {
      name: data.filename,
      option: selectedImage.mediaSize,
    });
    ReactGA.event("download_media", {
      photo_name: data.filename,
      option: selectedImage.mediaSize,
    });
    downloadMediaImage(selectedImage.media, data.filename);
    return;
  };

  const handleChangeImageSize = (value) => {
    setImageSize(value);
  };
  let image =
    isOpenDialog !== false &&
    _.find(data?.images, ["mediaSize", imageSize]) &&
    _.find(data?.images, ["mediaSize", imageSize]).media;
  return (
    <StyledMediaCloseDialog
      disablePortal
      onClose={() => {
        setIsOpenDialog(false);
        setTandcChecked(false);
      }}
      visible={isOpenDialog !== false}
    >
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogImg src={image && image + "-thumbnail"} />
          {data?.images && (
            <SelectTag
              isDefaultSelect
              title={t("chooseASize")}
              selectList={data?.images.map(({ mediaSize }) => mediaSize)}
              style={{ margin: "15px 0px" }}
              onChange={handleChangeImageSize}
            />
          )}
        </div>

        <DialogContent>
          <DownloadButton>
            <CommonButton
              type="primary"
              onClick={handleDownload}
              size="large"
              hoverBgColor="linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FF5C00"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CommonImage src={DownloadIcon} style={{ marginRight: 8 }} />
                {t("download")}
              </div>
            </CommonButton>
          </DownloadButton>
        </DialogContent>
      </Container>
    </StyledMediaCloseDialog>
  );
};

export default DownloadDialog;




const Container = styled.div`
  width: 560px;
  max-width: 100%;
`;

const CommonImage = styled.img`
  user-drag: none;
`;

const DialogContent = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const DialogImg = styled.img`
  user-drag: none;
  pointer-events: none;
  -webkit-touch-callout: none;
  object-fit: contain;
  border: 0.664px solid rgba(0, 0, 0, 0.13);
  @media ${breakpoint.mobile} {
    height: 200px;
    width: 200px;
  }
  @media ${breakpoint.desktop} {
    height: 300px;
    width: 300px;
  }
`;

const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
