import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import CommonButton from '../../../components/common/buttons/CommonButton';
import { useTranslation } from 'react-i18next';
import IconAdd from '../../../assets/ic-add.svg';
import AddressEditBox from './AddressEditBox';
import CommonCloseDialog from '../../../components/common/dialogs/CommonCloseDialog';
import ProfileAddAddressForm from '../ProfileAddAddressForm';
import { AppContext } from '../../../AppContext';
import { updateUser } from '../../../apis';
import { ResponsiveUI } from '../../../utils/responsive';

function AddressForm(props) {
  const { session, setSession } = useContext(AppContext);
  const { t, i18n } = useTranslation('profile');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);

  const handleAddNewAddress = async (newAddress) => {
    const body = {
      addresses: [newAddress],
    };
    const result = await updateUser(body, {
      session,
      setSession,
    });
    if (result?.success) {
      setDialogOpen(false);
    }
  };

  const handleRemoveAddress = async () => {
    const body = {
      addresses: [],
    };
    const result = await updateUser(body, {
      session,
      setSession,
    });
    if (result?.success) {
      setDialogOpen(false);
    }
  };

  // HARD CODE force show 1 address only.
  const addresses = session?.user?.addresses?.slice(0, 1) || [];
  const canAddNewAddress = addresses.length === 0;

  const _renderContent = (isMobile) => {
    return (
      <StyledMainDiv>
        {addresses.map((address, index) => (
          <Row>
            {/* Address box, show address and edit button */}
            <AddressEditBox
              onClickEdit={() => {
                setEditingAddress(address);
                setDialogOpen(true);
              }}
              address={address}
              label={t('profile:info.address', { number: index + 1 })}
            />
          </Row>
        ))}

        {canAddNewAddress && (
          <Row>
            {/* Add new Address button */}
            <CommonButton
              style={isMobile ? { width: '100%' } : {}}
              type="outlined"
              size="large"
              onClick={() => {
                setEditingAddress(null);
                setDialogOpen(true);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {t('profile:button.add_new_address')}
                <img src={IconAdd} style={{ marginLeft: '20px' }} />
              </div>
            </CommonButton>
          </Row>
        )}
        <CommonCloseDialog
          fullScreen={isMobile}
          onClose={() => setDialogOpen(false)}
          visible={dialogOpen}
        >
          <ProfileAddAddressForm
            isMobile={isMobile}
            editingAddress={editingAddress}
            onDelete={handleRemoveAddress}
            onSubmit={handleAddNewAddress}
          />
        </CommonCloseDialog>
      </StyledMainDiv>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export default AddressForm;
