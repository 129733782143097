import { updateTmpUser, updateUser } from '../../apis';
import styled from 'styled-components';
import CommonButton from '../../components/common/buttons/CommonButton';
import FormTextField from '../../components/common/textfields/FormTextField';
import FormSelect from '../../components/common/selects/FormSelect';
import { useState, useRef, useContext } from 'react';
import CommonText from '../../components/common/typographies/CommonText';
import { useTranslation } from 'react-i18next';
import Space from '../../components/common/Space';
import { AppContext } from '../../AppContext';
import Members from '../../utils/performers.js';
import CircleImage from '../../components/common/images/CircleImage';
import { Grid } from '@mui/material';

export default function Step4({ handlePageChange, setSessionExpired }) {
  const { t, i18n } = useTranslation('signin');
  const { session, setSession } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(
    session?.user?.supportingMembers || []
  );
  const handleMemberClick = (member) => {
    if (selectedMembers.includes(member)) {
      setSelectedMembers(selectedMembers.filter((item) => item != member));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };
  const handleNext = async (back = false) => {
    if (!loading) {
      setLoading(true);
      const body = {
        supportingMembers: selectedMembers,
      };
      console.log(body);
      let result = null;
      if (session?.user?.memberType == 'TMP')
        result = await updateTmpUser(body);
      else result = await updateUser(body);
      console.log(result);
      if (result?.success) {
        const newSession = {
          ...session,
          user: result.data,
        };
        localStorage.setItem('session', JSON.stringify(newSession));
        setSession(newSession);
        if (back) handlePageChange('step3');
        else handlePageChange('step5');
      } else {
        if (result.error?.code == 'E0002') {
          setSessionExpired(true);
        } else if (back) handlePageChange('step3');
      }
      setLoading(false);
    }
  };
  return (
    <Container>
      <CommonText lineHeight="140%">{t('register.step') + ' 4/6'}</CommonText>
      <Space size="30px" />
      <CommonText fontSize="20px" mFontSize="16px" lineHeight="140%">
        {t('register.supporting_members')}
      </CommonText>
      <Space size="97px" />
      <Grid
        container
        style={{ width: '100%', maxWidth: 438 }}
        spacing={1.5}
        justifyContent="center"
        alignItems="center"
      >
        {Members.map((item) => (
          <Grid
            item
            container
            xs={3}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircleImage
              width="100%"
              onClick={() => handleMemberClick(item.nameZh)}
              selected={selectedMembers.includes(item.nameZh)}
              src={item.image}
            />
          </Grid>
        ))}
      </Grid>
      <Space size="50px" />
      <ButtonContainer>
        <CommonButton
          style={{ width: '50%', maxWidth: 440 }}
          onClick={() => handleNext(true)}
        >
          {t('register.previous')}
        </CommonButton>
        <CommonButton
          disabled={selectedMembers.length == 0}
          style={{ width: '50%', maxWidth: 440, marginLeft: 30 }}
          type={'primary'}
          onClick={() => handleNext(false)}
        >
          {t('register.next')}
        </CommonButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 632px;
  width: 100%;
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;
