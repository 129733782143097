import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import constants from '../constants';
import CONSTANTS from '../constants';
import getDeviceType from '../utils/getDeviceType';

function getSession() {
  try {
    const sessionJSON = localStorage.getItem('session', '');
    const session = JSON.parse(sessionJSON);
    return session;
  } catch (error) {
    return null;
  }
}

// Obtain the fresh token each time the function is called
function getAccessToken() {
  try {
    const sessionJSON = localStorage.getItem('session', '');
    const session = JSON.parse(sessionJSON);
    return session?.accessToken;
  } catch (error) {
    return null;
  }
}

// Obtain the fresh token each time the function is called
function getRefreshToken() {
  try {
    const sessionJSON = localStorage.getItem('session', '');
    const session = JSON.parse(sessionJSON);
    return session?.refreshToken;
  } catch (error) {
    return null;
  }
}

// Use interceptor to inject the token to requests
axios.interceptors.request.use((request) => {
  let token = getAccessToken();
  if (token) request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  return request;
});

function setUpRefreshTokenLogic(setSession) {
  // Function that will be called to refresh authorization
  const refreshAuthLogic = (failedRequest) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const params = new URLSearchParams();
      params.append('refresh_token', refreshToken);
      params.append('grant_type', 'refresh_token');
      params.append('client_id', CONSTANTS.CLIENT_ID);
      params.append('client_secret', CONSTANTS.CLIENT_SECRET);
      return axios({
        method: 'post',
        url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: params,
        skipAuthRefresh: true,
      })
        .then((tokenRefreshResponse) => {
          if (
            tokenRefreshResponse.data?.success &&
            tokenRefreshResponse.data?.data
          ) {
            localStorage.setItem(
              'session',
              JSON.stringify(tokenRefreshResponse.data?.data)
            );
            setSession(tokenRefreshResponse.data?.data);
            failedRequest.response.config.headers['Authorization'] =
              'Bearer ' + tokenRefreshResponse.data?.data.accessToken;
          }
          return Promise.resolve();
        })
        .catch(async (error) => {
          console.log('tokenRefreshResponse error', error);
          localStorage.removeItem('session');
          setSession(null);
          return Promise.resolve();
        });
    }
  };
  createAuthRefreshInterceptor(axios, refreshAuthLogic);
}

const request = (param, refresh = true) => {
  return axios({
    ...param,
  })
    .then((res) => res.data)
    .catch(async (error) => {
      console.log('debug', error);
      return error?.response?.data || error?.response?.status;
    });
};

const register = (body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/register`,
    headers: { 'Content-Type': 'application/json' },
    data: body,
  });
};

const updateTmpUser = (body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/profile/tmp`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
};

const updateUser = (body, updateSessions) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  })
    .then((result) => {
      // Check if update session needed
      if (result.success) {
        const currentSession = getSession();
        if (currentSession) {
          const newSession = {
            ...currentSession,
            user: result.data,
          };
          localStorage.setItem('session', JSON.stringify(newSession));
          if (updateSessions && updateSessions.setSession)
            updateSessions.setSession(newSession);
        }
      }
      return result;
    })
    .catch((error) => {
      return { success: false, error };
    });
};

const subscribe = (body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/subscribe`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
};

const poll = (choice) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/poll`,

    data: { choice },
  });
};

const forgotPassword = (type, emailOrPhone, phoneOTP) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/forgot-password`,
    headers: { 'Content-Type': 'application/json' },
    data:
      type == 'email'
        ? { email: emailOrPhone }
        : { phone: emailOrPhone, phoneOTP },
  });
};

const resetPassword = (resetPasswordToken, password, retypePassword) => {
  let body = {
    resetPasswordToken: resetPasswordToken,
    password: password,
    retypePassword: retypePassword,
  };
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/reset-password`,
    headers: { 'Content-Type': 'application/json' },
    data: body,
  });
};

const requestEmailOTP = (email, purpose, username) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/email/otp`,
    headers: { 'Content-Type': 'application/json' },
    data: { email, purpose, username },
  });
};

const requestPhoneOTP = (phone) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/phone/otp`,
    headers: { 'Content-Type': 'application/json' },
    data: { phone },
  });
};

const login = (username, password) => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');
  params.append('client_id', constants.CLIENT_ID);
  params.append('client_secret', constants.CLIENT_SECRET);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const socialLogin = (type, accessToken) => {
  const params = new URLSearchParams();
  params.append('access_token', accessToken);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/${type}/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const appleLogin = (code, lastName, firstName) => {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('firstName', firstName);
  params.append('lastName', lastName);
  params.append('clientId', CONSTANTS.APPLE_SIGN_IN_CLIENT_ID);
  params.append('redirect_url', CONSTANTS.APPLE_SIGN_IN_REDIRECT_URI);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/apple/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const socialRegister = (
  type,
  accessToken,
  memberType,
  username,
  phone,
  promotion,
  language,
  contactEmail,
  contactEmailOTP
) => {
  const params = new URLSearchParams();
  params.append('access_token', accessToken);
  params.append('memberType', memberType);
  params.append('username', username);
  if (contactEmail) {
    params.append('contactEmail', contactEmail);
    params.append('contactEmailOTP', contactEmailOTP);
  }
  if (phone) params.append('phone', phone);
  if (promotion) params.append('promotion', promotion);
  if (language) params.append('language', language);
  // params.append('recaptcha', recaptcha);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/${type}/register`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};
const appleRegister = (
  code,
  lastName,
  firstName,
  memberType,
  username,
  phone,
  promotion,
  language,
  contactEmail,
  contactEmailOTP
) => {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('firstName', firstName);
  params.append('lastName', lastName);
  params.append('clientId', CONSTANTS.APPLE_SIGN_IN_CLIENT_ID);
  params.append('redirect_url', CONSTANTS.APPLE_SIGN_IN_REDIRECT_URI);
  params.append('memberType', memberType);
  params.append('username', username);
  if (contactEmail) {
    params.append('contactEmail', contactEmail);
    params.append('contactEmailOTP', contactEmailOTP);
  }
  if (phone) params.append('phone', phone);
  if (promotion) params.append('promotion', promotion);
  if (language) params.append('language', language);
  // params.append('recaptcha', recaptcha);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/apple/register`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const refreshToken = (refreshToken) => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');
  params.append('client_id', constants.CLIENT_ID);
  params.append('client_secret', constants.CLIENT_SECRET);
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/auth/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
  });
};

const getMenu = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/menu`,
  });
};
const getLanding = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/landing`,
  });
};
const getPopup = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/popup`,
  });
};
const listActivities = (filter, sort) => {
  let path = '';
  if (filter) {
    path = path + `?filter=${filter}`;
  }
  if (sort) {
    if (path) {
      path = path + `&sort=${sort}`;
    } else {
      path = path + `?sort=${sort}`;
    }
  }
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/activity${path}`,
  });
};
const enrollActivity = (id, body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/activity/${id}/enroll`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
};
const getOrderCounter = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/ordercounter/2025%20MIRO%20Subscription`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
// const enrollActivity = (body) => {
//     const params = new URLSearchParams();
//     params.append('captcha_settings', `{"keyname":"MakerVille_v2","fallback":"true","orgId":"00D8c000006KnjJ","ts":"${new Date().getTime()}"}`);
//     params.append('oid', "00D8c000006KnjJ");
//     params.append('retURL', "http://");
//     params.append('00N8c00000gY6GM', body.miroId);
//     params.append('00N8c00000gY6GR', body.name);
//     params.append('email', body.email);
//     params.append('phone', body.phone);
//     params.append('00N8c00000gY6Ep', body.type);
//     params.append('tac', 'on');
//     params.append('tac2', 'on');
//     params.append('g-recaptcha-response', body.recaptcha);
//     params.append('submit', "Submit");
//     console.log("params", body)
//     return fetch(`https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`, {
//         method: 'post',
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         body: params
//     });
// }

const getActivity = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/activity/${id}`,
  });
};

const listDiscographies = (filter, performerEn, performerZh, sort) => {
  let path = '';
  if (performerEn == 'all') {
    if (filter) {
      path = path + `?filter=${filter}`;
    }
  } else {
    if (path == '' && filter) {
      path = path + `?filter=${filter}`;
    } else if (path && filter) {
      path = path + `&filter=${filter}`;
    }

    if (path == '' && performerEn) {
      path = path + `?performerEn=${performerEn}`;
    } else if (path && performerEn) {
      path = path + `&performerEn=${performerEn}`;
    }

    if (path == '' && performerZh) {
      path = path + `?performerZh=${performerZh}`;
    } else if (path && performerZh) {
      path = path + `&performerZh=${performerZh}`;
    }
  }

  if (sort) {
    if (path) {
      path = path + `&sort=${sort}`;
    } else {
      path = path + `?sort=${sort}`;
    }
  }

  console.log('fetching2', path);
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography${path}`,
  });
};

const getDiscography = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography/${id}`,
  });
};
const getDiscographyRelated = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/discography/${id}/related`,
  });
};
const listMemberProfiles = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/memberprofile`,
  });
};
const getMemberProfile = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/memberprofile/${id}`,
  });
};
const getTeamProfile = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/teamprofile`,
  });
};
const getProfile = async ({ session, setSession }) => {
  const result = await request(
    {
      method: 'get',
      url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
      withCredentials: true,
    },
    true
  );
  if (result.success && result.data) {
    const currentSession = getSession();
    if (
      currentSession?.user?.memberType != result?.data?.memberType &&
      result?.data?.memberType == 'MIRO'
    ) {
      //workaround to gen new token here, this block should be removed after refresh token is fixed
      const refreshResult = await refreshToken(currentSession?.refreshToken);
      if (refreshResult.success) {
        localStorage.setItem('session', JSON.stringify(refreshResult.data));
        setSession({
          ...refreshResult.data,
          user: result.data,
        });
      }
    } else if (currentSession) {
      const newSession = {
        ...currentSession,
        user: result.data,
      };
      localStorage.setItem('session', JSON.stringify(newSession));
      setSession(newSession);
    }
  }
  return result;
};
const updateProfileWithSession = (body, updateSessions = {}) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/profile`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  })
    .then((result) => {
      // Check if update session needed
      if (result.success) {
        const currentSession = getSession();
        if (currentSession) {
          const newSession = {
            ...currentSession,
            user: result.data,
          };
          localStorage.setItem('session', JSON.stringify(newSession));
          if (updateSessions && updateSessions.setSession)
            updateSessions.setSession(newSession);
        }
      }
      return result;
    })
    .catch((error) => {
      return { success: false, error };
    });
};
const getOrders = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/orders`,
  });
};
const getOrderById = (id) => {
  return axios
    .get(`${CONSTANTS.API_ENDPOINT}/user/orders/${id}`)
    .then((res) => res?.data?.data);
};

const getMediaSeriesById = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media/series/${id}`,
  });
};

const getMediaTnC = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media/tnc`,
  });
};

const validateRenewMiroCode = (code) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/user/subscribe-code`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      code,
    }),
  });
};

// const contactUs = (body) => {
//     return request({
//         method: 'post',
//         url: `${CONSTANTS.API_ENDPOINT}/case`,
//         headers: { 'Content-Type': 'application/json' },
//         data: body
//     })
// }

const contactUs = (body) => {
  return request({
    method: 'post',
    url: `${CONSTANTS.API_ENDPOINT}/case`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });
  // const params = new URLSearchParams();
  // params.append(
  //   "captcha_settings",
  //   `{"keyname":"MakerVille_v2","fallback":"true","orgId":"00D8c000006KnjJ","ts":"${new Date().getTime()}"}`
  // );
  // params.append("orgid", "00D8c000006KnjJ");
  // params.append("retURL", "http://");
  // params.append("debug", "1");
  // params.append("00N8c00000ddCEh", body.type);
  // params.append("name", body.username);
  // params.append("email", body.email);
  // params.append("subject", "Contact Form Inquiry - " + body.type);
  // params.append("description", body.message);
  // params.append("g-recaptcha-response", body.recaptcha);
  // params.append("submit", "Submit");
  // if (body.phone) params.append("phone", body.phone);
  // if (body.memberNo) params.append("00N8c00000ddCEm", body.memberNo);
  // if (body.file) params.append("00N8c00000ddCHb", body.file);
  // console.log("params", body);
  // return request({
  //   method: "post",
  //   url: `https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8`,
  //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //   data: params,
  // });
};

const listMedia = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media`,
  });
};

const listMediaSeries = () => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/media/series`,
  });
};

const listRegions = (type, lang) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/region?lang=${lang}&type=${type}&source=sf`,
  });
};
const listAreas = (type, region, lang) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/area?lang=${lang}&type=${type}&${lang}.region=${region}&source=sf`,
  });
};
const listAddresses = (type, area, lang) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/address?lang=${lang}&type=${type}&${lang}.area=${area}&source=sf`,
  });
};
const getPayment = (id) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/user/payment/${id}`,
  });
};

const checkoutStream = (contentId, streaming) => {
  const params = new URLSearchParams();
  params.append('contentId', contentId);
  params.append('type', getDeviceType());
  if (streaming) params.append('streaming', streaming);
  return request({
    method: 'post',
    url: CONSTANTS.CHECKOUT_DOMAIN + '/checkout',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: params,
  });
};
const keepaliveStream = (contentId, session) => {
  const params = new URLSearchParams();
  params.append('contentId', contentId);
  params.append('session', session);
  return request({
    method: 'post',
    url: CONSTANTS.CHECKOUT_DOMAIN + '/keepalive',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: params,
  });
};

const getStaticPage = (pageKey) => {
  return request({
    method: 'get',
    url: `${CONSTANTS.API_ENDPOINT}/staticpage/page/${pageKey}`,
  });
};

export {
  setUpRefreshTokenLogic,
  register,
  updateTmpUser,
  updateUser,
  forgotPassword,
  resetPassword,
  requestEmailOTP,
  requestPhoneOTP,
  subscribe,
  poll,
  // getPaymentResult,
  updateProfileWithSession,
  getOrders,
  getOrderById,
  getMediaSeriesById,
  getMediaTnC,
  getProfile,
  login,
  socialLogin,
  appleLogin,
  socialRegister,
  appleRegister,
  refreshToken,
  getLanding,
  getMenu,
  getPopup,
  listActivities,
  getActivity,
  enrollActivity,
  // getActivityEnrollment,
  listDiscographies,
  getDiscography,
  getDiscographyRelated,
  getTeamProfile,
  listMemberProfiles,
  getMemberProfile,
  contactUs,
  listMedia,
  listMediaSeries,
  listRegions,
  listAreas,
  listAddresses,
  getPayment,
  getOrderCounter,
  checkoutStream,
  keepaliveStream,
  getStaticPage,
  // renew miro code
  validateRenewMiroCode,
};
