import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import IconArrowLeftBlack from '../../../assets/ic-arrowLeft-black.svg';
import CommonText from '../../../components/common/typographies/CommonText';
import CommonDivider from '../../../components/common/divider/CommonDivider';
import CommonLabel from '../../../components/common/labels/CommonLabel';
import OrderDetailHeader from './OrderDetailHeader';
import CommonButton from '../../../components/common/buttons/CommonButton';
import { formatAddress } from '../../../utils/addressFormat';
import { AppContext } from '../../../AppContext';
import { Link, useParams } from 'react-router-dom';
import { getOrderById } from '../../../apis';
import { useQuery } from 'react-query';
import { ProfileMenuItem } from '../../../pages/ProfileMenu';
import Order2022Icon from '../../../assets/welcome_kit_2022.jpg';
import Order2023Icon from '../../../assets/welcome_kit_2023.jpg';
import Order2024Icon from '../../../assets/welcome_kit_2024.jpg';
import Order2025Icon from '../../../assets/welcome_kit_2025.jpg';
import { ResponsiveUI, breakpoint } from '../../../utils/responsive';
import Space from '../../../components/common/Space';
import moment from 'moment';
import constants from '../../../constants';

const icons = {
  '2025 MIRO Subscription': Order2025Icon,
  '2024 MIRO Subscription': Order2024Icon,
  '2023 MIRO Subscription': Order2023Icon,
  'MIRO Subscription': Order2022Icon,
};
const years = {
  '2025 MIRO Subscription': '2025',
  '2024 MIRO Subscription': '2024',
  '2023 MIRO Subscription': '2023',
  'MIRO Subscription': '2022',
};

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

function OrderDetailInfo(props) {
  const { session } = useContext(AppContext);
  const { t, i18n } = useTranslation(['profile', 'common']);
  const { orderId } = useParams();
  const { isLoading, isError, data, error } = useQuery('getOrderById', () =>
    getOrderById(orderId)
  );
  const order = data;
  if (!order) {
    return null;
  }

  console.log('order', order);
  const MIRO_year = years[order?.item?.name];
  const order_icon = icons[order?.item?.name];
  const optin_miro_pack =
    moment(order?.createdAt).year() >= 2024 ? order?.address?.date : true;
  const isLateJoin =
    order?.item?.amount == constants.LATE_MEMBER_PRICE_PER_YEAR;
  const _renderContent = (isMobile) => {
    return (
      <div style={{ margin: '20px' }}>
        <StyledMainDiv>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {!isMobile && (
              <Link to={`/${ProfileMenuItem.PROFILE_ORDER}`}>
                <IconButton style={{ marginRight: '10px' }}>
                  <img
                    src={IconArrowLeftBlack}
                    style={{
                      width: '20px',
                      height: '20px',
                      alignSelf: 'center',
                    }}
                  />
                </IconButton>
              </Link>
            )}

            <CommonText
              fontWeight="600"
              fontSize="30px"
              mFontSize="24px"
              lineHeight="35px"
              style={{ textAlign: 'start', overflowWrap: 'anywhere' }}
            >
              {t('order.order', { order_number: order._id })}
            </CommonText>
          </div>

          <StyledDetailDiv isMobile={isMobile}>
            <OrderDetailHeader order={order} />

            <Space size={isMobile ? '15px' : '30px'} />
            <CommonDivider />
            <Space size={isMobile ? '40px' : '60px'} />

            <CommonText lineHeight="35px" fontWeight="600" fontSize="30px">
              {t('order.activity_goods')}
            </CommonText>

            <Space size={isMobile ? '13px' : '35px'} />
            <StyledProductContentDiv>
              {/* 收貨人 */}
              {/* <StyledRowDiv>
                <StyledSpaceBetweenDiv>
                  <CommonLabel style={{ opacity: "0.3", fontSize: "14px" }}>
                    {t("order.receiver")}
                  </CommonLabel>

                  <CommonLabel style={{ color: "#FF5C00", fontSize: "14px" }}>
                    {t(`order.status.${order.status.toLowerCase()}`)}
                  </CommonLabel>
                </StyledSpaceBetweenDiv>
                <CommonText
                  style={{ textAlign: "start", overflowWrap: "anywhere" }}
                  lineHeight="25px"
                >
                  {`${session?.user?.firstName} ${session?.user?.lastName}${
                    session?.user?.phone ? ` (${session?.user?.phone})` : ""
                  }`}
                </CommonText>
              </StyledRowDiv> */}

              {/* 收貨地址 */}
              {optin_miro_pack && (
                <>
                  <StyledRowDiv>
                    {/* <AddressContainer> */}
                    {order?.address?.type && (
                      <>
                        <CommonText color="#515151" fontSize="14px">
                          {t('common:address.type')}
                        </CommonText>
                        <CommonText>
                          {t('common:address.' + order?.address?.type)}
                        </CommonText>
                        <Space size="20px" />
                      </>
                    )}
                    <CommonText color="#515151" fontSize="14px">
                      {t('common:address.Express_address')}
                    </CommonText>
                    <CommonText>
                      {formatAddress(order?.address, i18n.language)}
                    </CommonText>
                    <Space size="20px" />
                    <CommonText color="#515151" fontSize="14px">
                      {t('common:address.name')}
                    </CommonText>
                    <CommonText>
                      {order?.address?.name ||
                        `${session?.user?.firstName} ${session?.user?.lastName}`}
                    </CommonText>
                    <Space size="20px" />
                    <CommonText color="#515151" fontSize="14px">
                      {t('common:address.phone')}
                    </CommonText>
                    <CommonText>
                      {order?.address?.phone ||
                      session?.user?.addresses[0]?.phone
                        ? insert(
                            order?.address?.phone ||
                              session?.user?.addresses[0]?.phone,
                            4,
                            ' '
                          )
                        : ''}
                    </CommonText>
                    {/* </AddressContainer> */}
                    {/* <CommonLabel style={{ opacity: "0.3", fontSize: "14px" }}>
                  {t("order.receiver_address")}
                </CommonLabel>
                <CommonText lineHeight="25px">
                  {formatAddress(order?.address, i18n.language)}
                </CommonText> */}
                  </StyledRowDiv>

                  <CommonDivider style={{ marginBottom: '15px' }} />
                </>
              )}

              {/* Product */}
              <StyledRowDiv>
                <StyledProductRowDiv padding="0px">
                  <ProductImage src={order_icon} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: 174,
                      marginLeft: 15,
                      flex: 1,
                    }}
                  >
                    <CommonText fontWeight="600" fontSize="14px">
                      {MIRO_year}{' '}
                      {t('profile:order.miro_subscription_welcome_pack')}
                      {isLateJoin && t('profile:order.late_miro_fee')}
                    </CommonText>
                    {optin_miro_pack ? (
                      <CommonText
                        fontWeight="400"
                        lineHeight="140%"
                        fontSize="14px"
                      >
                        {order?.deliveryDate &&
                          !isLateJoin &&
                          t('profile:order.delivery_date', {
                            deliveryDate:
                              i18n.language == 'zh'
                                ? order?.deliveryDate.split('/')[0]
                                : order?.deliveryDate.split('/')[1],
                          })}
                      </CommonText>
                    ) : (
                      <CommonText fontSize="14px">
                        {t('profile:order.optout_miro_pack')}
                      </CommonText>
                    )}
                    <div style={{ flex: 1, height: '100%' }} />
                    {optin_miro_pack && (
                      <CommonText
                        fontWeight="400"
                        lineHeight="140%"
                        fontSize="14px"
                        color="rgb(17,17,17,0.6)"
                      >
                        {isLateJoin
                          ? t('profile:order.late_delivery_remark')
                          : t('profile:order.delivery_remark', {
                              deliveryDate: moment(
                                order?.deliveryDate,
                                'YYYY-M-D'
                              )
                                .locale('en')
                                .format(
                                  i18n.language == 'zh'
                                    ? 'YYYY年M月D日'
                                    : 'D MMMM YYYY'
                                ),
                            })}
                      </CommonText>
                    )}
                  </div>
                  <CommonText fontSize="14px">{`$${order.item.amount}`}</CommonText>

                  {/* <CommonLabel>{"尺碼：中碼\n顏色：黑色"}</CommonLabel> */}
                </StyledProductRowDiv>
              </StyledRowDiv>

              <CommonDivider style={{ marginBottom: '15px' }} />

              {/* 小計 */}
              <StyledRowDiv>
                <StyledSpaceBetweenDiv>
                  <CommonText>{t('order.subtotal')}</CommonText>
                  <CommonText>{`$${order.item.amount}`}</CommonText>
                </StyledSpaceBetweenDiv>

                {optin_miro_pack && (
                  <>
                    <CommonText>
                      {t('profile:order.shipping_fee')}{' '}
                      {t('common:address.' + (order?.address?.type || 'Old'))}
                    </CommonText>
                    <Space size="15px" />
                  </>
                )}

                {/* Tracking button */}
                {order.tracking && (
                  <CommonButton
                    type="outlined"
                    {...(isMobile ? { style: { width: '100%' } } : {})}
                  >
                    {t('order.track_shipping')}
                  </CommonButton>
                )}
              </StyledRowDiv>
            </StyledProductContentDiv>
          </StyledDetailDiv>
        </StyledMainDiv>
      </div>
    );
  };

  return (
    <ResponsiveUI
      mobile={_renderContent(true)}
      desktop={_renderContent(false)}
    />
  );
}

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;
const StyledDetailDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  ${(props) => (props.isMobile ? '' : 'margin-left: 46px')};
`;
const StyledProductContentDiv = styled.div`
  padding: 15px 0 15px 0;
  margin-bottom: 50px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
`;
const StyledRowDiv = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px 0 15px;
  text-align: left;
`;
const StyledProductRowDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: ${(props) => (props.padding ? props.padding : '0 15px 0 15px')};
`;
const StyledSpaceBetweenDiv = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 20px;
  width: 100%;
`;

const ProductImage = styled.img`
  height: auto;

  @media ${breakpoint.desktop} {
    width: 174px;
  }

  @media ${breakpoint.mobile} {
    width: 100px;
  }
`;

export default OrderDetailInfo;
