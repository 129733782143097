import React from 'react';
import styled from 'styled-components';
import ActivityHeaderImage from '../../assets/activity_header.svg';
import ActivityHeaderMobileImage from '../../assets/activity_header_mobile.svg';
import { breakpoint, ResponsiveUI } from '../../utils/responsive';
import { useTranslation } from 'react-i18next';
import TitleGroup from '../../components/common/typographies/TitleGroup';

export default function MainSection({ data }) {
  const { t, i18n } = useTranslation();
  return (
    <StyledDiv>
      <ResponsiveUI
        mobile={
          <StyledTitleDiv>
            <TitleGroup
              zhTitle="特別活動"
              enTitle="ACTIVITY"
              zhTitleStyle={{ fontSize: 20, lineHeight: 1.5 }}
              enTitleStyle={{ fontSize: 40, lineHeight: 1 }}
            />
            <StyledHeaderImageDiv>
              <img src={ActivityHeaderMobileImage} />
            </StyledHeaderImageDiv>
          </StyledTitleDiv>
        }
        desktop={
          <StyledTitleDiv>
            <TitleGroup
              zhTitle="特別活動"
              enTitle="ACTIVITY"
              zhTitleStyle={{ fontSize: 20, lineHeight: 1.5 }}
              enTitleStyle={{ fontSize: 40, lineHeight: 1 }}
            />
            <StyledHeaderImageDiv>
              <img src={ActivityHeaderImage} />
            </StyledHeaderImageDiv>
          </StyledTitleDiv>
        }
      />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitleDiv = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  @media ${breakpoint.desktop} {
    margin-top: 112px;
    margin-bottom: 103px;
  }

  @media ${breakpoint[1024]} {
    margin-top: 77px;
    margin-bottom: 103px;
  }

  @media ${breakpoint.mobile} {
    min-height: 80px;
    margin-bottom: 20px;
  }
`;

const StyledHeaderImageDiv = styled.div`
  position: absolute;
  left: 50%;
  margin: -23px 0px 0px 50px;
  z-index: -1;
  @media ${breakpoint.desktop} {
    margin: -50px 0px 0px 140px;
  }
  @media ${breakpoint.mobile} {
    margin: -20px 0px 0px 80px;
  }
`;
