import { useMediaQuery } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import MarcoFlagImg from "../../../assets/home_marco_flag.svg";
import { breakpoint } from '../../../utils/responsive';
import MediaContainer from './eshopSession/MediaContainer';
import ProductsContainer from './eshopSession/ProductContainer';

const mockProducts = [
  {
    _id: '6662c7442418c89b24d0fe71',
    nameZh: 'TEST《Hi MIRO! 見面祭》',
    nameEn: 'TEST《Hi MIRO! 見面祭》',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
  {
    _id: '6662c7442418c89b24d0fe72',
    nameZh: 'TEST df ds《Hi MIRO! 見面祭》 212 ',
    nameEn: 'TEST fsd f《Hi MIRO! 見面祭》',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
  {
    _id: '6662c7442418c89b24d0fe73',
    nameZh: 'TEST《Hisdf f ds MIRO! 見面祭》 122 1',
    nameEn: 'TEST《Hf dsfi MIRO! 見面祭》2 1 21',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
  {
    _id: '6662c7442418c89b24d0fe74',
    nameZh: 'TEST《Hi MIRO! 見面祭》 122 1',
    nameEn: 'TEST《Hif ds f MIRO! 見面祭》2 1 21',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
];

const mockMediaSeries = [
  {
    _id: '6662c7442418c89b24d0fe71',
    nameZh: 'TEST《Hi MIRO! 見面祭》 12 321',
    nameEn: 'TEST《Hi MIRO! 見面祭》 123 12',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
  {
    _id: '6662c7442418c89b24d0fe72',
    nameZh: 'TEST《Hi MIR213 12O! 見面祭》3 12',
    nameEn: 'TEST《Hi MI 312 321 RO! 見面祭》',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
  {
    _id: '6662c7442418c89b24d0fe73',
    nameZh: 'TEST《H1423 4 23i MIRO! 見面祭》',
    nameEn: 'TESsf sd fdsT4 324 《Hi MIRO! 見面祭》',
    thumbnail: 'https://static-dev.mirrorweare.com/mediaseries/1717749605913',
  },
];

const EshopSession = ({ data }) => {
  const { products, mediaSeries } = data || {};
  // mediaSeries = mockMediaSeries;
  // products = mockProducts;
  const isLargerThan1024 = useMediaQuery(breakpoint[1024]);

  const [shopCarouselIdx, setShopCarouselIdx] = React.useState(0);

  if (!products && !mediaSeries) return null;
  return (
    <>
      <StyledContainer>
        <MediaContainer
          mediaSeries={mediaSeries}
          hasShopItems={products?.length > 0}
        />
        {products.length > 0 && (
          <ProductsContainer
            products={products}
            idx={shopCarouselIdx}
            afterChange={(currentIdx) => setShopCarouselIdx(currentIdx)}
          />
        )}
        {isLargerThan1024 && (
          <MirrorLogo
            src={MarcoFlagImg}
            alt="mirror logo"
            aria-label="mirror logo"
          />
        )}
      </StyledContainer>
    </>
  );
};

const MirrorLogo = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(30%, 50%);

  @media ${breakpoint.largeDesktop} {
    transform: translate(20%, 50%);
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 14px;
  @media ${breakpoint[1024]} {
    flex-direction: row;
    margin-bottom: 90px;
    gap: 20px;
  }
  @media ${breakpoint.largeDesktop} {
    gap: 50px;
  }

  .home-title-group {
    padding-bottom: 20px;
    @media ${breakpoint[1024]} {
      padding-bottom: 30px;
    }
  }
`;

export default EshopSession;
