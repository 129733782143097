import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { updateProfileWithSession } from '../../apis';
import { AppContext } from '../../AppContext';
import CommonButton from '../../components/common/buttons/CommonButton';
import TickIcon from '../../components/common/icons/TickIcon';
import Space from '../../components/common/Space';
import PasswordTextField from '../../components/common/textfields/PasswordTextField';
import CommonText from '../../components/common/typographies/CommonText';

export default function ResetPassword({ handleResetSuccess, isMobile }) {
  const { t } = useTranslation(['profile', 'signin']);
  const { session, setSession } = useContext(AppContext);
  const [page, setPage] = useState('reset');

  const [currentPw, setCurrentPw] = useState(null);
  const [newPw, setNewPw] = useState(null);
  const [confirmPw, setConfirmPw] = useState(null);

  const handleResetPassword = async () => {
    const body = {
      oldPassword: currentPw,
      password: newPw,
      retypePassword: confirmPw,
    };
    const result = await updateProfileWithSession(body, {
      session,
      setSession,
    });
    if (result.success) {
      setPage('resetSuccess');
    }
  };

  function validateNewPassword(pw) {
    var errors = [];
    if (pw.length < 8) {
      errors.push('Your password must be at least 8 characters');
    }
    if (pw.search(/[a-z]/i) < 0) {
      errors.push('Your password must contain at least one lowercase letter.');
    }
    if (pw.search(/[A-Z]/i) < 0) {
      errors.push('Your password must contain at least one uppercase letter.');
    }
    if (pw.search(/[0-9]/) < 0) {
      errors.push('Your password must contain at least one digit.');
    }
    if (errors.length > 0) {
      // alert(errors.join("\n"));
      return false;
    }
    return true;
  }

  function canSubmit() {
    return (
      currentPw !== null &&
      newPw !== null &&
      confirmPw !== null &&
      confirmPw === newPw &&
      validateNewPassword(newPw)
    );
  }

  if (page === 'resetSuccess') {
    return (
      <Container isMobile={isMobile}>
        <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
          {t('signin:forgotpassword.reset_password_success')}
        </CommonText>
        <Space size="20px" />
        <TickIcon />
        <Space size="20px" />
        <CommonText fontSize="20px" lineHeight="40px">
          {t('signin:forgotpassword.reset_password_success_hint')}
        </CommonText>
        <Space size="20px" />
        <CommonButton
          {...(isMobile ? { style: { width: '100%' } } : {})}
          size="large"
          normalCase={true}
          type={'primary'}
          onClick={() => handleResetSuccess()}
        >
          {t('signin:forgotpassword.relogin')}
        </CommonButton>
      </Container>
    );
  }

  return (
    <Container isMobile={isMobile}>
      <CommonText fontWeight={600} fontSize="30px" lineHeight="35px">
        {t('profile:reset_pw.title')}
      </CommonText>
      <Space size="24px" />
      <PasswordTextField
        onChange={(e) => setCurrentPw(e.target.value)}
        label={t('reset_pw.current_pw')}
      />
      <Space size="20px" />
      <PasswordTextField
        onChange={(e) => setNewPw(e.target.value)}
        label={t('reset_pw.new_pw')}
      />

      <ul
        style={{ marginLeft: '30px', display: 'flex', alignSelf: 'flex-start' }}
      >
        <li>
          <CommonText>{t('reset_pw.pw_desc')}</CommonText>
        </li>
      </ul>
      <Space size="20px" />

      <PasswordTextField
        onChange={(e) => setConfirmPw(e.target.value)}
        label={t('reset_pw.confirm_pw')}
      />

      <Space size="20px" />
      <ButtonContainer>
        <CommonButton
          {...(isMobile ? { width: '100%' } : {})}
          disabled={!canSubmit()}
          normalCase={true}
          type={'primary'}
          onClick={handleResetPassword}
        >
          {t('profile:button.submit_button')}
        </CommonButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? '100%' : '592px')};
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
